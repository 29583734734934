@import "../../assets/less/variables.less";

.homepage-wrapper {
    .nopage-wrapper{
        min-height: calc(100vh - 200px);
        text-align: center;
        display: flex;
        align-items: center;
        margin-top: 6rem;
        h2{
            font-size: 30px;
            margin-bottom: 10px;
        }
        p{
            text-transform: uppercase;
            font-weight: 300;
            font-size: 15px;
            margin-bottom: 5rem;
        }
        .ant-btn {
            padding: 0 38px;
        }
        .icon-col{
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 400px;
            margin: auto auto 3rem auto;
            .icon-left{
                fill: @white;
                width: 30%;
                position: relative;
                left: 20px;
            }
            .icon-center{
                width: 40%;
                -webkit-animation: rotating 5s linear infinite;
                -moz-animation: rotating 5s linear infinite;
                -ms-animation: rotating 5s linear infinite;
                -o-animation: rotating 5s linear infinite;
                animation: rotating 5s linear infinite;
            }
            .icon-right{
                fill: @white;
                width: 30%;
                position: relative;
                right: 20px;
            }
        }
    }
}

@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }