@import "../../../assets/less/variables.less";

.homepage-wrapper {
    &.blog-details {
        position: relative;
        z-index: 1;
        .list-unstyled {
            list-style-type: none;
        }
        .banner-section {
            padding-top: 12rem;
            position: relative;
            z-index: 2;
            h1{
                font-size: 2.8rem;
            }
        }
        .banner-bg {
            z-index: -1;
            position: absolute;
            right: 0;
            top: 0;
            max-width: 50vw;
        }
        .blog-dtl-banner {
            width: 100%;
            border-radius: 25px;
            margin: 1.5rem auto;
        }
        .blog-details-section {
            ul, ol {
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
                padding-left: 0;
                li {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.4;
                    color: rgba(@white, 0.8);
                }
            }
            img {
                max-width: 100%;
                border-radius: 25px;
                margin: 3rem auto;
            }
            p {
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
            h3 {
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 1.5rem;
            }
        }
    }
}