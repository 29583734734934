@import "../../assets/less/variables.less";

.homepage-wrapper {
    &.mediakit-wrapper {
        .banner-section {
            .banner-row {
                text-align: left;
                min-height: 500px;
                padding: 12rem 0 0;

                @media screen and (max-width:991px) {
                    padding: 6rem 0 0;
                }

                p {
                    margin-left: 0;
                    @media screen and (max-width:576px) {
                        margin-bottom: 0;
                    }
                }

                h1 {
                    font-size: 5rem;
                    @media screen and (max-width:991px) {
                        font-size: 2.5rem;
                        line-height: 1.3;
                    }
    
                    @media screen and (max-width:576px) {
                        font-size: 1.875rem;
                    }
                }
            }

            .explore-banner {
                position: absolute;
                right: 0;
                top: 0;
                max-width: 70vw;
                z-index: 0;
            }
        }

        .mediakit-section {
            padding: 8rem 0;
            position: relative;

            @media screen and (max-width:767px) {
                padding: 4rem 0;
            }

            .left-line {
                position: absolute;
                left: 0;
                top: 16%;
                max-width: 20vw;
            }

            .left-bg {
                position: absolute;
                left: 0;
                top: 44%;
                max-width: 20vw;
            }

            .left-line2 {
                position: absolute;
                left: 0;
                top: 40%;
                max-width: 20vw;
            }

            .left-bg2 {
                position: absolute;
                left: 0;
                top: 66%;
                max-width: 20vw;
            }

            .globe-img {
                position: absolute;
                right: 0;
                top: 2%;
                width: 100%;
                max-width: 60vw;
                @media screen and (max-width:991px) {
                    top: -2%;
                }
            }

            .kit-row {
                padding: 4rem 0;

                h3 {
                    font-size: 2.1rem;
                    font-weight: 600;
                    margin-bottom: 1.4rem;
                }

                .logo-row {
                    margin-top: 3.2rem;

                    .logo-list {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: 2rem;
                        list-style-type: none;
                        padding-left: 0;
                        margin-bottom: 2rem;
                        margin-top: 1.8rem;

                        @media screen and (max-width:991px) {
                            grid-template-columns: repeat(2, 1fr);
                            grid-gap: 1.5rem;
                        }

                        @media screen and (max-width:576px) {
                            grid-template-columns: repeat(1, 1fr);
                            grid-gap: 1rem;
                        }

                        li {
                            border: 1px solid #a5c9ff2c;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-height: 150px;
                            border-radius: 25px;

                            @media screen and (max-width:576px) {
                                min-height: 130px;
                                border-radius: 16px;
                            }

                            img {
                                max-height: 60px;
                            }
                        }
                    }
                }

                .color-row {
                    margin-top: 4rem;

                    .color-list {
                        display: flex;
                        flex-wrap: wrap;
                        list-style-type: none;
                        padding-left: 0;
                        margin-top: 1.8rem;

                        li {
                            margin-right: 2rem;

                            .color-code {
                                width: 190px;
                                height: 190px;
                                border-radius: 25px;
                                margin-bottom: 1.3rem;
                            }

                            .color-name {
                                h4 {
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                }

                                p {
                                    font-size: 1rem;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }

                .communication-list {
                    display: flex;
                    flex-wrap: wrap;
                    list-style-type: none;
                    padding-left: 0;
                    margin-top: 0;

                    @media screen and (max-width: 576px) {
                        margin: auto;
                        justify-content: center;
                    }

                    li {
                        margin-right: 3rem;
                        text-align: center;

                        .images {
                            width: 200px;
                            height: 200px;
                            border-radius: 200px;
                            border: 4px solid @white;
                            overflow: hidden;
                            background: #051E42;
                            margin-bottom: 15px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        p {
                            color: @white;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}