@import "../../assets/less/variables.less";

.homepage-wrapper {
    &.getcmdx-wrapper {
        .banner-section {
            position: relative;
            .left-circle {
                max-width: 50vw;
                position: absolute;
                left: 0;
                top: 7%;
            }
            .right-bg {
                position: absolute;
                right: 0;
                top: -2%;
                z-index: 0;
                max-width: 55vw;
                @media screen and (max-width:640px) {
                    top: 5%;
                    opacity: 0.6;
                }
            }
            .banner-row {
                text-align: left;
                position: relative;
                z-index: 1;

                @media screen and (max-width:576px) {
                    min-height: auto;
                    padding: 8rem 0 6rem 0;
                }

                p {
                    margin-left: 0;
                }

                h1 {
                    font-size: 5rem;
                    @media screen and (max-width:991px) {
                        font-size: 2.5rem;
                        line-height: 1.3;
                    }
    
                    @media screen and (max-width:576px) {
                        font-size: 1.875rem;
                    }
                }

                .right-col {
                    text-align: right;
                    @media screen and (max-width:576px) {
                        text-align: center;
                    }
                    img {
                        max-width: 430px;
                        @media screen and (max-width:576px) {
                            max-width: 80%;
                        }
                    }
                }
            }
        }

        .explore-section-alt {
            padding: 5rem 0;
        }

        .getcmdx-section {
            padding: 4rem 0;
            position: relative;
            z-index: 1;
            .getcmdx-row {
                padding: 3rem 0;
                align-items: center;

                &:nth-child(odd) {
                    @media screen and (max-width:767px) {
                        flex-direction: column-reverse;
                    }
                }

                .getcmdx-text {
                    display: flex;
                    @media screen and (max-width:767px) {
                        margin-top: 3rem;
                    }
                    .icon-circle {
                        width: 82px;
                        height: 82px;
                        border-radius: 82px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #0F489F4D;
                        min-width: 82px;
                        margin-right: 1.8rem;
                        @media screen and (max-width:767px) {
                            width: 60px;
                            height: 60px;
                            border-radius: 60px;
                            min-width: 60px;
                            margin-right: 1rem;
                        }
                        .icon-circle-inner {
                            width: 68px;
                            height: 68px;
                            border-radius: 68px;
                            font-size: 2rem;
                            font-weight: 800;
                            background: #0F489F4D;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @media screen and (max-width:767px) {
                                width: 48px;
                                height: 48px;
                                border-radius: 48px;
                                font-size: 1.2rem;
                            }
                        }
                    }
                    h2{
                        margin-bottom: 1.8rem;
                        @media screen and (max-width:767px) {
                            font-size: 1.4rem;
                        }
                    }
                    p {
                        margin-bottom: 1.9rem;
                        @media screen and (max-width:767px) {
                            font-size: 1.1rem;
                        }
                    }
                }
                .getcmdx-immage {
                    text-align: center;
                    position: relative;
                    &.getcmdx-immage1 {
                        .cube-1 {
                            position: absolute;
                            top: -20%;
                            right: 0;
                            max-width: 230px;
                        }
                        .cube-2 {
                            position: absolute;
                            bottom: -10%;
                            left: 0;
                            max-width: 230px;
                        }
                        .cube-3 {
                            position: absolute;
                            left: 0;
                            bottom: 16%;
                            max-width: 32px;
                        }
                        .cube-4 {
                            position: absolute;
                            right: 0;
                            top: 2.5%;
                            max-width: 32px;
                        }
                    }
                    &.getcmdx-immage2 {
                        .helix-icon {
                            position: absolute;
                            top: -20%;
                            left: 0;
                            max-width: 180px;
                        }
                        .cube-1 {
                            position: absolute;
                            bottom: -5%;
                            right: 5%;
                            max-width: 200px;
                        }
                        .cube-2 {
                            position: absolute;
                            bottom: 12%;
                            right: 5%;
                            max-width: 30px;   
                        }
                    }
                    &.getcmdx-immage3 {
                        .cube-1 {
                            position: absolute;
                            top: -20%;
                            right: 0;
                            max-width: 230px;
                        }
                        .cube-2 {
                            position: absolute;
                            bottom: -10%;
                            left: 0;
                            max-width: 230px;
                        }
                        .cube-3 {
                            position: absolute;
                            left: 0;
                            bottom: 9%;
                            max-width: 32px;
                        }
                        .cube-4 {
                            position: absolute;
                            right: 0;
                            top: 2.5%;
                            max-width: 32px;
                        }
                    }
                    &.getcmdx-immage4 {
                        .cube-1 {
                            position: absolute;
                            top: -20%;
                            left: -10%;
                            max-width: 230px;
                        }
                        .cube-2 {
                            position: absolute;
                            bottom: -30%;
                            right: 0;
                            max-width: 230px;
                        }
                        .cube-3 {
                            position: absolute;
                            left: 18%;
                            top: 2%;
                            max-width: 32px;
                        }
                        .cube-4 {
                            position: absolute;
                            right: 22%;
                            bottom: -18%;
                            max-width: 32px;
                        }
                    }
                }
            }
        }

    }
}