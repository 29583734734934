@import "../../assets/less/variables.less";

.homepage-wrapper {
    // overflow-x: hidden;
    &.introduction-wrapper {
        .banner-section {
            position: relative;
            .blue-right {
                position: absolute;
                right: 0;
                top: 14%;
                width: 30vw;
                z-index: 0;
            }
            .blue-left {
                position: absolute;
                left: 0;
                top: -10%;
                width: 30vw;
                z-index: 0;
            }
            .welcome-solar {
                position: absolute;
                top: 5%;
                left: 0;
                right: 0;
                width: 90vw;
                margin: auto;
                opacity: 0.7;
                z-index: 1;
            }
        }
        .about-section {
            position: relative;
            .left-bg {
                position: absolute;
                left: 0;
                max-width: 30vw;
                top: -22%;
                @media screen and (max-width:767px) {
                    max-width: 15vw;
                    top: -10%;
                }
            }
        }
        .comdex-intro{
            .row-1 {
                align-items: center;
                padding: 4rem 0;

                @media screen and (max-width:767px) {
                    flex-direction: column-reverse;
                }

                .col-img {
                    position: relative;
                    text-align: center;

                    @media screen and (max-width:767px) {
                        margin-bottom: 4rem;
                    }
                    
                    img {
                        max-width: 470px;
                        @media screen and (max-width:767px){
                            max-width: 100%;
                        }
                    }
                    @keyframes rotating {
                        from {
                          -ms-transform: rotate(0deg);
                          -moz-transform: rotate(0deg);
                          -webkit-transform: rotate(0deg);
                          -o-transform: rotate(0deg);
                          transform: rotate(0deg);
                        }
                        to {
                          -ms-transform: rotate(360deg);
                          -moz-transform: rotate(360deg);
                          -webkit-transform: rotate(360deg);
                          -o-transform: rotate(360deg);
                          transform: rotate(360deg);
                        }
                      }
                    .secure-img {
                        -webkit-animation: rotating 5s linear infinite;
                        -moz-animation: rotating 5s linear infinite;
                        -ms-animation: rotating 5s linear infinite;
                        -o-animation: rotating 5s linear infinite;
                        animation: rotating 5s linear infinite;
                    }
                    .cube-1 {
                        position: absolute;
                        top: -10%;
                        left: 8%;
                        max-width: 230px;
                        @media screen and (max-width:600px){
                            max-width: 150px;
                        }
                    }
                    .cube-2 {
                        position: absolute;
                        bottom: -18%;
                        right: 4%;
                        max-width: 230px;
                        @media screen and (max-width:600px){
                            max-width: 150px;
                            right: 8%;
                        }
                    }
                    .cube-3 {
                        position: absolute;
                        bottom: 8%;
                        right: 4%;
                        max-width: 40px;
                    }
                    .cube-4 {
                        position: absolute;
                        top: 20%;
                        left: 8%;
                        max-width: 35px;
                    }
                }
            }
            .row-2 {
                align-items: center;
                padding: 4rem 0;
                .col-img {
                    position: relative;
                    text-align: center;
                    @media screen and (max-width:767px) {
                        margin-bottom: 4rem;
                    }
                    .helix-img {
                        position: absolute;
                        right: 5%;
                        top: -28%;
                        max-width: 250px;
                        @media screen and (max-width:600px){
                            max-width: 150px;
                        }
                    }
                    img {
                        max-width: 450px;
                        @media screen and (max-width:767px){
                            max-width: 100%;
                        }
                    }
                    .cube-1 {
                        position: absolute;
                        bottom: -28%;
                        left: 0;
                        max-width: 250px;
                        @media screen and (max-width:600px){
                            max-width: 150px;
                            bottom: -15%;
                        }
                    }
                    .cube-2 {
                        position: absolute;
                        bottom: -12%;
                        left: 5%;
                        max-width: 40px;
                    }
                }
            }
            .row-3 {
                align-items: center;
                padding: 4rem 0;
                @media screen and (max-width:767px) {
                    flex-direction: column-reverse;
                }
                .col-img {
                    position: relative;
                    text-align: center;
                    @media screen and (max-width:767px) {
                        margin-bottom: 4rem;
                    }
                    .helix-img {
                        position: absolute;
                        left: 0;
                        bottom: -22%;
                        max-width: 250px;
                        @media screen and (max-width:600px){
                            max-width: 150px;
                        }
                    }
                    img {
                        max-width: 470px;
                        margin-left: auto;
                        @media screen and (max-width:767px){
                            max-width: 100%;
                        }
                    }
                    .cube-1 {
                        position: absolute;
                        top: -28%;
                        right: 0;
                        max-width: 220px;
                        @media screen and (max-width:600px){
                            width: 150px;
                        }
                    }
                    .cube-2 {
                        position: absolute;
                        top: 0;
                        right: 0;
                        max-width: 40px;
                    }
                }
            }
        }

        .plugmodule-section {
            padding: 10rem 0;
            position: relative;
            .line-bg {
                position: absolute;
                left: 0;
                max-width: 22vw;
                bottom: -22%;
            }
        }

    }
}



.statics-section-alt {
    padding: 4rem 0;
    position: relative;
    z-index: 1;

    ul {
        list-style-type: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2.5rem;

        @media screen and (max-width:1024px) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
        }

        @media screen and (max-width:576px) {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            .card {
                padding: 1px;
                height: 100%;
                border-radius: 10px;

                .card-inner {
                    background: rgba(@secondary-color, 0.1);
                    border-radius: 10px;
                    height: 100%;
                    padding: 1.8rem;
                    display: flex;
                    align-items: center;
                    position: relative;

                    @media screen and (max-width:1380px) {
                        padding: 1.2rem;
                    }

                    @media screen and (max-width:767px) {
                        padding: 1.3rem;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        inset: 0;
                        border-radius: 12px; 
                        padding: 1px; 
                        background:linear-gradient(-90deg,#053277,rgba(rgba(@white, 0.77))); 
                        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                        -webkit-mask-composite: xor;
                        mask-composite: exclude; 
                    }

                    .icon {
                        fill: @white;
                        height: 45px;
                        width: 45px;
                        min-width: 45px;
                        margin:0 1.5rem 0 0;
                        @media screen and (max-width:767px) {
                            width: 35px;
                            height: 35px;
                            min-width: 35px;
                            margin: 0 1rem 0 0;
                        }
                    }

                    h3 {
                        margin-bottom: 8px;
                        font-weight: 700;
                        font-size: 1.4rem;
                        display: flex;
                        align-items: center;
                        @media screen and (max-width:1380px) {
                            font-size: 1.1rem;
                        }
                        @media screen and (max-width:767px) {
                            font-size: 1rem;
                        }
                        .icon {
                            width: 18px;
                            height: 18px;
                            margin-left: 8px;
                            margin-right: 0;
                        }
                    }

                    p {
                        margin-bottom: 0;
                        color: @text-color-primary;
                        font-size: @font-size-base;
                        @media screen and (max-width:767px) {
                            font-size: @font-size-base - 1;
                        }
                    }
                }
            }
        }
    }
    &.grid-2 {
        ul {
            grid-template-columns: repeat(2, 1fr);
            @media screen and (max-width:767px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}