@import "../../../assets/less/variables.less";

.article-card {
    background: rgba(@white, 0.05);
    border-radius: 20px;
    transition: .5s ease;

    &:hover {
        transform: scale(1.05);
        // .article-card-img {
        //     img {
        //         transform: scale(1.05);
        //     }
        // }
    }

    .article-card-img {
        height: 207px;
        border-radius: 20px 20px 0 0;
        overflow: hidden;

        img {
            transition: .5s ease;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .article-card-bottom {
        padding: 1.5rem 2rem;

        .datetime {
            color: rgba(@white, 0.8);
            margin-bottom: 0.6rem;
        }

        h3 {
            font-size: 1.2rem;
            font-weight: 700;
            min-height: 50px;
            max-width: 96%;
        }

        p {
            font-size: @font-size-sm + 1;
        }

        .ant-btn {
            border: 0;
            text-align: left;
            padding: 0;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: @font-size-sm;
            font-weight: 400;

            .icon {
                width: 13px;
                margin-left: 10px;
                right: -17px;
            }

            span {
                margin-inline-end: 0px !important;
                position: unset;
            }
        }
    }
}