@import "../../assets/less/variables.less";

.homepage-wrapper {
    &.contact-wrapper {
        padding: 10rem 0;
        .contact-info{
            p {
                font-weight: 400;
                span {
                    color: @white;
                    font-weight: 500;
                }
            }
        }
        .right-red-bg {
            position: absolute;
            right: 0;
            top: 1%;
            max-width: 500px;
        }
        .contact-solar {
            position: absolute;
            left: 0;
            bottom: 5%;
            max-width: 700px;
        }
        .contact-form {
            background: linear-gradient(133.91deg, rgba(15, 72, 159, 0.3) -2.91%, rgba(4, 12, 31, 0.38) 99.03%);
            padding: 2rem;
            border-radius: 1.6rem;
            max-width: 490px;
            width: 100%;
            margin: auto;
            position: relative;
            z-index: 3;

            @media screen and (max-width:767px) {
                margin-top: 3rem;
            }

            .ant-form-item {
                .ant-form-item-label {
                    color: @white;
                    label {
                        color: @white;
                        &::before {
                            display: none;
                        }
                    }
                }
                .ant-input {
                    background: none;
                    border-width: 0 0 1px 0;
                    border-color: rgba(15, 72, 159, 0.3);
                    border-radius: 0;
                    min-height: 50px;
                    font-weight: 400;
                    outline: none;
                    color: @white;
                    box-shadow: none;
                    outline: none;
                    &::placeholder {
                        color: rgba(@white, 0.8);
                        font-weight: 400;
                    }
                }
            }
        }
    }
}