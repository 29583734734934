@import "../../assets/less/variables.less";


.menulink-item {
    display: flex;

    .left-icon {
        width: 32px;
        height: 28px;
        min-width: 32px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: @white;
        margin-right: 10px;
    }

    .right-col {
        .title {
            font-size: 0.92rem;
            font-weight: 700;
            color: @white;
            margin-bottom: 4px;
            line-height: 1;
        }

        .menu-text {
            font-size: @font-size-sm;
            font-weight: 400;
            color: @white;
            opacity: 0.8;
            line-height: 1.3;
        }
    }
}

.getcmdx-li {
    background: #0A1B44;
    padding: 0.3rem 1.5rem !important;
    margin-bottom: -10px !important;
    margin-top: 10px !important;
    .get-cmdx-bottom {
        border-radius: 0 0 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        right: 0;
        .getmenu-icon{
            width: 125px;
            height: 75px;
            object-fit: cover;
            margin-right: -20px;
        }
    }
}


.header-wrapper {
    background: #01081780;
    backdrop-filter: blur(50px);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 3;

    .header-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        position: relative;
        padding: 14px 0;

        .logo {
            height: 100%;
            display: flex;
            align-items: center;

            a {
                display: flex;
                align-items: center;
            }

            .icon {
                width: 120px;
            }

            img {
                width: 140px;
            }
        }

        .center-menu {
            width: 100%;

            .ant-menu {
                width: 100%;
                display: flex;
                justify-content: center;
                background: none;
                border: 0;
                font-family: @font-family;
                font-weight: 500;

                li {
                    color: @white;
                    font-size: @font-size-base + 1;
                    background: none;

                    &::after {
                        display: none;
                    }

                    .ant-menu-submenu-title {
                        .ant-menu-title-content {
                            color: @white;
                        }
                    }

                    a {
                        color: @white;

                        &.active {
                            color: #9AB6DB !important;
                        }

                        &:hover {
                            color: #9AB6DB !important;
                        }
                    }

                    &.ant-menu-item-selected {
                        background: none;
                    }

                    &:hover {
                        color: #9AB6DB !important;
                    }
                }
            }
        }

        .menu-icon {
            width: 50%;
            position: absolute;
            right: 0;
            text-align: right;

            .icon {
                fill: @white;
                width: 20px;
                cursor: pointer;
            }
        }
    }
}

.mobilemenu {
    padding: 20px;
    min-width: 50vw;

    .ant-menu {
        line-height: 1.5;
        padding: 1rem 0;

        li {
            padding: 10px;
        }
    }
}

.non-url {
    opacity: 0.6;
    pointer-events: none;
}

.mobile-drawer {
    background: #09132B !important;
    margin-top: 0;
    overflow: initial !important;
    border-radius: 0 0 10px 10px;

    .ant-drawer-header-title {
        justify-content: flex-end;
        .ant-drawer-close {
            margin-right: 0;
        }
    }
    
    .ant-drawer-close {
        svg {
            fill: @white;
        }
    }

    .ant-drawer-header {
        text-align: right;
        justify-content: flex-end;
    }

    .ant-drawer-body {
        min-height: 100%;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        overflow: initial;
    }


    .ant-tabs  {
        padding: 0 22px;
        height: 100%;
        .ant-tabs-content  {
            height: 100%;
        }
        .ant-tabs-nav {
            font-family: @font-family;
            margin-bottom: 5px;
            .ant-tabs-ink-bar  {
                display: none;
            }
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    color: rgba(#E6E6E8, 0.60);
                    font-size: @font-size-base + 1;
                    font-weight: 500;
                    margin: 0 0 0 20px;
                    &:first-child {
                        margin: 0;
                    }
                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: @white;
                        }
                    }
                }
            }
        }
    }

    .drawer-bottom {
        background: #0F1B37;
        padding: 3px 25px;
        border-radius: 0 0 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: -30px;
        left: -22px;
        right: 0;
        width: calc(100% + 44px);
        .getmenu-icon{
            width: 125px;
            height: 78px;
            object-fit: cover;
            margin-right: -10px;
        }
    }

    .ant-menu {
        background: none !important;
        border: 0;

        .ant-menu-submenu-title {
            padding: 5px !important;
            background: none !important;
        }

        &.ant-menu-dark {
            background: none !important;
            background-color: none !important;
        }

        .ant-menu-item {
            font-size: 1.2rem;
            font-weight: 600;
            padding: 15px 2px !important;
            height: auto;

            a {
                color: @white;
            }

            .ant-menu-title-content {
                color: @white;
            }

            &.ant-menu-item-selected {
                background: none;

                a {
                    color: @primary-color;
                }

                .ant-menu-title-content {
                    color: @white;
                }
            }
        }
    }

    .footer-links {
        justify-content: space-between;
        display: flex;
        align-items: center;
        margin-top: auto;

        .ant-btn {
            padding: 5px 4px;

            .icon {
                fill: @primary-color;
            }
        }
    }
}

.ant-menu-submenu-popup {
    background: #09132B;
    border-radius: 10px;

    .ant-menu {
        background-color: #09132B !important;
        background: none;
        padding: 10px 0 !important;

        .ant-menu-item {
            height: auto;
            line-height: 1.3;
            padding: 1.1rem 1.5rem;
            width: 100%;
            margin: 0;

            &:active {
                background-color: rgba(#0F1B37, 0.5) !important;
            }

            &.ant-menu-item-selected {
                background: #0F1B37;
            }
        }
    }
}