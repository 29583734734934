@import "../../../assets/less/variables.less";

.homepage-wrapper {
    &.introduction-dtl-wrapper {
        overflow-x: initial;
        .into-dtl {
            img {
                width: 100%;
            }
            a {
                word-break: break-all;
            }
            .bottom-link {
                a {
                    color: @white;
                    text-decoration: underline;
                }
            }
            h3 {
                font-size: 22px;
            }
            pre {
                // color: #79CFFF;
                // font-size: 22px;
                // font-weight: 700;
                font-style: italic;
                // overflow: initial;
                // word-break: break-all;
                // white-space: break-spaces;
                display: inline-grid !important;
                width: 100%;
                background: #072041 !important;
                @media screen and (max-width:1400px) {
                    font-size: 18px;
                }
                @media screen and (max-width:1300px) {
                    font-size: 17px;
                }
                @media screen and (max-width:1200px) {
                    font-size: 16px;
                }
                .language-go {
                    overflow-x: auto !important;
                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 5px;
                    }
                    
                    &::-webkit-scrollbar-track {
                        background: #D9D9D9;
                        border-radius: 5px;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background-color: #1873A9;
                        width: 5px;
                        height: 5px;
                        border-radius: 5px;
                    }
                }
            }
            ol {
                padding-left: 1rem;
                font-family: @font-family;
                li {
                    font-size: 16px;
                    line-height: 1.6;
                    padding: 10px 0;
                    font-weight: 400;
                    color: rgba(@white, 0.7);
                }
                .point-list {
                    font-weight: 500;
                    position: relative;
                    padding-left: 0px;
                    &::before {
                        content: ' ';
                        width: 2px;
                        height: 2px;
                        display: inline-block;
                        background: @white;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            .list-2 {
                list-style-type: none;
                margin-bottom: 2.2rem;      
                font-size: 1rem;
                font-weight: 400;
                padding-left: 0;      
                margin-top: 0.8rem;     
                font-family: @font-family;       
                li {
                    position: relative;
                    padding: 8px 0 8px 15px;
                    color: rgba(@white, 0.7);
                    line-height: 1.6;
                    &::before {
                        width: 2px;
                        height: 2px;
                        background: @white;
                        position: absolute;
                        content: ' ';
                        display: inline-block;
                        left: 0;
                        top: 20px;
                    }
                }
            }
        }
        .introductiondtl-section {
            padding: 8rem 0;
            .introductiondtl-row {
                display: flex;
                align-items: flex-start;
                @media screen and (max-width:991px) {
                    flex-direction: column;
                }
                .introductiondtl-left {
                    padding-right: 1.5rem;
                    position: sticky;
                    top: 90px;
                    min-width: 340px;
                    @media screen and (max-width:1200px) {
                        min-width: 260px;
                        padding-right: 0.5rem;
                    }
                    @media screen and (max-width:991px) {
                        min-width: 100%;
                    }
                    .ant-collapse-item {
                        .ant-collapse-header {
                            display: flex;
                            justify-content: space-between;
                            font-size: 0.95rem;
                            font-weight: 700;
                            margin-bottom: 0;
                            color: rgba(@white, 0.8);
                            .ant-collapse-header-text {
                                a {
                                    padding: 0;
                                }
                            }
                            .ant-collapse-expand-icon {
                                svg {
                                    fill: @white;
                                }
                            }
                        }
                        &.px-0 {
                            .ant-collapse-header {
                                padding-left: 0 !important;
                                padding-right: 0 !important;
                            }
                        }
                        &.ant-collapse-item-active {
                            .ant-collapse-header {
                                color: @white;
                                .ant-collapse-expand-icon {
                                    svg {
                                        fill: @white;
                                    }
                                }
                            }
                        }
                        .ant-collapse-item {
                            .ant-collapse-header {
                                padding: 6px 8px;
                                a{
                                    font-size: 0.80rem;
                                }
                            }
                            .ant-collapse-content-box {
                                padding: 4px 10px;
                            }
                            .ant-collapse-item {
                                .ant-collapse-header {
                                    padding: 6px 8px;
                                }
                            }
                        }
                    }
                    a {
                        color: rgba(@white, 0.6);
                        padding: 5px 0;
                        display: block;
                        font-size: 0.90rem;
                        line-height: 1.3;
                        &.active {
                            color: @white;
                        }
                    }
                }
                .introductiondtl-right {
                    padding-left: 2.8rem;
                    @media screen and (max-width:1200px) {
                        padding-left: 1rem;
                    }
                    @media screen and (max-width:991px) {
                        padding-right: 1rem;
                        padding-left: 1rem;
                    }
                    .nextup-bottom {
                        padding-top: 4rem;
                    }
                }
                .ant-breadcrumb {
                    margin-bottom: 1.5rem;
                    @media screen and (max-width:576px) {
                        margin-top: 2rem;
                    }
                    li {
                        color: @white;
                        font-weight: 600;
                        letter-spacing: 2px;
                        padding: 4px 0;
                        font-size: @font-size-base - 2;
                        text-transform: uppercase;
                        a {
                            color: rgba(@white, 0.5);
                        }
                        &.ant-breadcrumb-separator {
                            .icon {
                                fill: @white;
                                width: 8px;
                            }
                        }
                    }
                }
                .info-list {
                    display: flex;
                    align-items: center;
                    list-style-type: none;
                    padding-left: 0;
                    margin-top: 1.8rem;
                    @media screen and (max-width:767px) {
                        flex-direction: column;
                        text-align: left;
                        align-items: flex-start;
                    }
                    li {
                        display: flex;
                        align-items: center;
                        margin-right: 2rem;
                        @media screen and (max-width:767px) {
                            padding: 0.8rem 0;
                            text-align: left;
                            margin: 0;
                        }
                        svg {
                            fill: @white;
                            margin-right: 4px;
                        }
                    }
                }
                .course-outline {
                    padding: 4rem 0;
                    h1{
                        font-size: 2rem;
                        @media screen and (max-width:767px) {
                            font-size: 1.6rem;
                        }
                    }
                    .course-row {
                        padding: 2rem 0;
                        border-bottom: 1px solid rgba(15, 72, 159, 0.2);
                        display: flex;
                        width: 100%;
                        &:last-child {
                            border: 0;
                        }
                        .left-col {
                            text-align: center;
                            width: 130px;
                            margin-right: 3rem;
                            h3 {
                                font-size: 1.6rem;
                                font-weight: 500;
                                @media screen and (max-width:767px) {
                                    font-size: 1.1rem;
                                }
                            }
                            h2 {
                                font-size: 2.8rem;
                                font-weight: 500;
                                @media screen and (max-width:767px) {
                                    font-size: 2rem;
                                }
                            }
                        }
                        .right-col {
                            width: 100%;
                            h3 {
                                font-size: 1.6rem;
                                @media screen and (max-width:767px) {
                                    font-size: 1.2rem;
                                    line-height: 1.4;
                                }
                            }
                            ul {
                                list-style-type: none;
                                padding-left: 0;
                                margin-bottom: 0;
                                li {
                                    padding: 5px 0 5px 12px;
                                    color: rgba(@white, 0.9);
                                    position: relative;
                                    font-weight: 400;
                                    a {
                                        color: rgba(@white, 0.9);
                                        &:hover {
                                            color: @white;
                                        }
                                    }
                                    &::before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        width: 2px;
                                        height: 2px;
                                        background: @white;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                }
                            }
                        }
                    }
                }
                .learn-module {
                    padding: 3rem 0;
                    .learn-m-card {
                        background: linear-gradient(92.45deg, #427DF4 -5.29%, rgba(15, 72, 159, 0.6) 119.29%);
                        color: @white;
                        padding: 2rem;
                        border-radius: 10px;
                        margin-top: 1.5rem;
                        margin-bottom: 3rem;
                        p {
                            margin-bottom: 0;
                        }
                        ul {
                            list-style-type: none;
                            margin-bottom: 0;      
                            font-size: 1rem;
                            font-weight: 400;
                            padding-left: 0;      
                            margin-top: 0.8rem;            
                            li {
                                position: relative;
                                padding: 5px 0 5px 15px;
                                color: rgba(@white, 0.7);
                                &::before {
                                    width: 2px;
                                    height: 2px;
                                    background: @white;
                                    position: absolute;
                                    content: ' ';
                                    display: inline-block;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }
                    .code-div {
                        font-size: 1.2rem;
                        font-weight: 700;
                        font-style: italic;
                        color: #79CFFF;
                    }
                    ol {
                        padding-left: 1rem;
                        li {
                            font-size: 1rem;
                            padding: 5px 0;
                            font-weight: 400;
                            color: rgba(@white, 0.9);
                        }
                    }
                    .point-list {
                        font-weight: 500;
                        position: relative;
                        padding-left: 10px;
                        &::before {
                            content: ' ';
                            width: 2px;
                            height: 2px;
                            display: inline-block;
                            background: @white;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    
                    h3 {
                        font-size: 1.5rem;
                        margin-bottom: 1rem;
                    }
                    h4 {
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 1rem;
                    }
                    p {
                        margin-bottom: 2rem;
                    }
                    pre {
                        background: #072041 !important;
                    }
                }
            }
        }
        .ratethis-page {
            text-align: center;
            padding: 3rem 0;
            .ant-btn {
                background: none;
                padding: 5px 10px;
                border: 0;
                .icon {
                    fill: rgba(@white, 0.5);
                    width: 20px;
                }
            }
        }
    }   
}