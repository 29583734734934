@import "./assets/less/style.less";

.scrolltop-arrow {
    position: fixed;
    z-index: 3;
    bottom: 5%;
    right: 40px;
    background: #053277;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    padding: 0;
    border: 0;
    padding: 4px 0 0 0;
    transition: all ease-in 0.1s;
    &:hover {
        transform: scale(1.1);
    }
    .icon {
        fill: #ffffff;
    }
}