@import "../../assets/less/variables.less";

.homepage-wrapper {
  &.blog-wrapper {
    .banner-section {
      .banner-row {
        text-align: left;
        min-height: 620px;
        padding-top: 74px;

        .bank-btn {
          display: flex;
          align-items: center;
          color: @white;
          text-transform: uppercase;
          margin-bottom: 1.4rem;
          .icon {
            fill: @white;
            margin-right: 8px;
          }
        }

        p {
          margin-left: 0;
        }

        h1 {
          font-size: 5rem;
          @media screen and (max-width:991px) {
            font-size: 2.5rem;
            line-height: 1.3;
        }

        @media screen and (max-width:576px) {
            font-size: 1.875rem;
        }
        }
      }

      .blogbanner-bg {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 70vw;
      }
    }

    .explore-section-alt {
      padding: 5rem 0;
    }

    .bloglist-section {
      padding: 4rem 0;
      position: relative;

      .red-right {
        position: absolute;
        right: 0;
        top: -5%;
        opacity: 0.6;
        max-width: 30vw;
      }

      .left-line {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.6;
        max-width: 20vw;
      }

      .left-bg {
        position: absolute;
        left: 0;
        top: 42%;
        opacity: 0.6;
        max-width: 30vw;
      }

      .left-line2 {
        position: absolute;
        left: 0;
        top: 80%;
        opacity: 0.6;
        max-width: 18vw;
      }

      .left-bg2 {
        position: absolute;
        left: 0;
        top: 80%;
        opacity: 0.6;
        max-width: 22vw;
      }

      .right-line2 {
        position: absolute;
        right: 0;
        top: 50%;
        opacity: 0.6;
        max-width: 18vw;
      }

      .right-bg2 {
        position: absolute;
        right: 0;
        top: 50%;
        opacity: 0.6;
        max-width: 22vw;
      }

      .airdrop-card {
        padding: 0;
        margin-top: 3.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 20px;
        overflow: hidden;
        @media only screen and (max-width: 1366px) {
          background-size: auto;
          border-radius: 15px;
        }
        @media only screen and (max-width: 900px) {
          background-size: auto;
          border-radius: 10px;
        }
        @media only screen and (max-width: 800px) {
          background-size: auto;
        }
        @media only screen and (max-width: 700px) {
          background-size: auto;
          border-radius: 6px;
        }
        .banner-img {
          width: 100%;
        }
        .card-left {
          .ant-btn {
            background: none;
            padding: 0;
            display: flex;
            align-items: center;
            font-size: 1.1rem;
            font-weight: 600;
            border: 0;
            .icon {
              fill: @white;
              margin-right: 8px;
            }
          }
        }
        .card-right {
          img {
            max-width: 200px;
          }
        }
      }

      .bloglist-list-row {
        align-items: center;
        padding: 0 0 1rem 0;
        .bloglist-img {
          border-radius: 25px;
          max-width: 100%;
          @media screen and (max-width:767px) {
            margin-bottom: 1.4rem;
          }
        }
        .bloglist-content {
          h4 {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 1.2rem;
            opacity: 0.5;
          }
          h2 {
            max-width: 90%;
            @media screen and (max-width:767px) {
              font-size: 1.7rem;
            }
          }
          .location-row {
            display: flex;
            align-items: center;
            font-size: 1rem;
            .icon {
              fill: @white;
              width: 13px;
              margin-right: 6px;
            }
          }
          .ant-btn {
            padding: 0 25px 0 0;
            border: 0;
            font-size: @font-size-base - 1;
            text-transform: uppercase;
            letter-spacing: 2px;
            .icon {
              width: 14px;
            }
          }
        }
      }
      .acticle-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3.3rem;
        margin-top: 3.8rem;
        @media screen and (max-width:1200px) {
          grid-gap: 2rem;
        }
        @media screen and (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width:576px) {
            grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
