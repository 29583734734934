@import "../../assets/less/variables.less";

.homepage-wrapper {
    &.eventslist-wrapper {
        .banner-section {
            .banner-row {
                text-align: left;

                @media screen and (max-width:767px) {
                    min-height: auto;
                    padding: 10rem 0;
                }

                .bank-btn{
                    display: flex;
                    align-items: center;
                    color: @white;
                    text-transform: uppercase;
                    margin-bottom: 1.4rem;
                    .icon {
                        fill: @white;
                        margin-right: 8px;
                    }
                }

                p {
                    margin-left: 0;
                }

                h1 {
                    font-size: 5rem;
                    @media screen and (max-width:991px) {
                        font-size: 2.5rem;
                        line-height: 1.3;
                    }
    
                    @media screen and (max-width:576px) {
                        font-size: 1.875rem;
                    }
                }
            }

            .eventbanner-bg {
                position: absolute;
                right: 0;
                top: 0;
                max-width: 70vw;
            }
        }

        .explore-section-alt {
            padding: 5rem 0;
        }

        .list {
            list-style-type: none;
            padding-left: 0;
            display: grid;
            grid-gap: 3rem;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            margin-top: 3.8rem;

            li {
                margin-top: 0;

                .explore-card {
                    height: 100%;

                    .explore-card-inner {
                        .card-upper {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;

                            .left-col {
                                .icon-col {
                                    width: 68px;
                                    height: 68px;
                                    border-radius: 68px;
                                    margin-bottom: 1rem;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }

                                h3 {
                                    font-size: 1.7rem;
                                    font-weight: 700;
                                }
                            }

                            .right-col {
                                text-align: right;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;

                                .name {
                                    background: linear-gradient(94.77deg, rgba(15, 72, 159, 0.3) -106.7%, #4DFFFF 162.99%);
                                    padding: 2px 10px;
                                    font-size: 0.8rem;
                                    letter-spacing: 2px;
                                    margin-bottom: 10px;
                                }

                                .status {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    text-transform: uppercase;
                                    letter-spacing: 2px;
                                    font-size: 0.75rem;

                                    span {
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 8px;
                                        display: inline-block;
                                        margin-right: 6px;
                                    }

                                    &.live {
                                        color: #68E971;

                                        span {
                                            background: #68E971;
                                        }
                                    }

                                    &.commingsoon {
                                        color: #87B0EFB2;

                                        span {
                                            background: #87B0EFB2;
                                        }
                                    }
                                }
                            }
                        }

                        .card-bottom {
                            h3 {
                                font-size: 1.7rem;
                                font-weight: 700;
                            }

                            ul {
                                list-style-type: none;
                                padding-left: 0;
                                margin: 0 0 15px 0;

                                li {
                                    padding: 10px 10px 10px 20px;
                                    color: rgba(@white, 0.8);
                                    font-weight: 400;
                                    position: relative;

                                    &::before {
                                        width: 9px;
                                        height: 9px;
                                        border-radius: 9px;
                                        background: #0749B0;
                                        border: 2px solid #063175;
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                }
                            }

                            .ant-btn {
                                border: 0;
                                text-transform: uppercase;
                                padding: 0;
                                font-size: @font-size-sm - 1;
                                letter-spacing: 2px;
                                font-weight: 400;

                                .icon {
                                    right: -24px;
                                    width: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .eventlist-section {
            position: relative;
            .red-right {
                position: absolute;
                right: 0;
                top: -4%;
                max-width: 30vw;
            }
            .blue-left {
                position: absolute;
                left: 0;
                bottom: -15%;
                max-width: 35vw;
            }
            .event-list-row {
                align-items: center;
                .event-img {
                    border-radius: 25px;
                    max-width: 100%;
                }
                .event-content {
                    @media screen and (max-width:767px) {
                        margin-top: 3rem;
                    }
                    h4{
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 1.2rem;
                    }
                    h2{
                        max-width: 90%;
                    }
                    .location-row {
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        .icon {
                            fill: @white;
                            width: 13px;
                            margin-right: 6px;
                        }
                    }
                    .ant-btn {
                        padding: 0 25px 0 0;
                        border: 0;
                        font-size: @font-size-base - 1;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        .icon {
                            width: 14px;
                        }
                    }
                }
            }
            .past-events-list{
                margin-top: 4.5rem;
                margin-bottom: 7rem;
                .event-list-row {
                    margin-bottom: 2rem;
                    .event-content {
                        padding: 2.5rem;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}