/*
*
Setup
*                                                           
*/
@import "./variables.less";

html,
body {
  min-height: 100vh;
  font-family: @font-family;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  font-size: @font-size-base + 2;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  background: @secondary-color;
  color: @white;
  overflow-x: hidden;
}
main {
  position: relative;
  // overflow-x: hidden;
}
a {
  text-decoration: none;
}
.icon {
  width: 16px;
  height: auto;
  fill: #000;
  transition: fill 0.3s;
  &.icon-white {
    fill: #fff;
  }
}

#root {
  // overflow-x: hidden;
  font-family: @font-family;
}

.anchor-tag {
  cursor: pointer;
}

a {
  font-family: @font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: @font-family;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  color: @white;
}

h2 {
  font-weight: bold;
  color: @white;
  font-size: 2.1rem;
}

h3 {
  font-weight: 600;
  color: @white;
}

h4 {
  color: @white;
}

p {
  font-size: @font-size-base + 2;
  font-family: @font-family;
  color: @white;
  opacity: 0.7;
  line-height: 1.5;
}

.ant-btn {
  height: 36px;
  line-height: 36px;
  padding: 0 35px;
  font-weight: 500;
  font-family: @font-family !important;
  &.ant-btn-link {
    height: auto;
    line-height: initial;
    font-weight: 300;
    font-size: 1.063rem;
    letter-spacing: 0;
    padding: 0;
    white-space: inherit;
    text-align: left;
  }
  &.with-icon {
    position: relative;
    .icon {
      position: absolute;
      right: 0;
      fill: @white;
      top: 48%;
      transform: translateY(-50%);
    }
  }
  &.ant-btn-primary {
    background: @primary-color;
    border: 0;
    color: @white;
    border-radius: 39px;
    &:hover {
      background: darken(@primary-color, 5);
      border-color: darken(@primary-color, 5);
    }
  }
  &.ant-btn-secondary {
    background: none;
    border: 0;
    color: @white;
    border-radius: 39px;
    border: 1px solid @white;
    span {
      position: relative;
      z-index: 1;
    }
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
  &.ant-btn-lg {
    height: 42px;
    line-height: 42px;
    padding: 0 45px;
  }
}

.ant-radio-wrapper {
  font-size: 13px;
  font-weight: 600;
  .ant-radio {
    .ant-radio-inner {
      border-color: @black;
    }
    &.ant-radio-checked {
      .ant-radio-inner {
        &::after {
          background: #dd4a4a;
        }
      }
    }
  }
}

.ant-select {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 40px;
      line-height: 40px;
      background-color: #0f3a79;
      color: @white;
      border: 1px solid #0f3a79;
      border-radius: 6px;
      &:hover,
      &:focus {
        border: 1px solid #0f3a79;
      }
      input {
        height: 40px !important;
        line-height: 100%;
      }
      .ant-select-selection-item {
        line-height: 38px;
      }
      .ant-select-selection-placeholder {
        line-height: 38px;
        color: @white;
      }
    }
    .ant-select-arrow {
      .icon {
        width: 13px;
      }
    }
  }
}

.ant-layout {
  background: none;
  .ant-layout-header {
    background: rgba(1, 8, 23, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    @media screen and (max-width: 991px) {
      padding: 15px;
    }
  }
  .ant-layout-content {
    padding: 0;
  }
  .footer-bottom {
    padding: 0;
    background: none !important;
  }
}

.headings {
  text-align: center;
  p {
    color: #d7cfcfcc;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  }
  h1 {
    font-size: 3.75rem;
    font-weight: 800;
    margin-bottom: 0;
    line-height: 1.1;
    @media screen and (max-width: 991px) {
      font-size: 1.6rem;
    }
  }
  .divider {
    width: 170px;
    height: 4px;
    background: @gradient-bg;
    display: inline-block;
  }
  h4 {
    font-weight: 400;
    font-size: 25px;
    margin-top: 0.8rem;
  }
}

.social-right {
  position: fixed;
  top: 30vh;
  right: 0;
  width: 59px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.1px solid rgba(@white, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 12px 0px 0px 12px;
  z-index: 9;
  padding: 0.6rem 0;
  text-align: center;
  @media screen and (max-width: 991px) {
    width: 30px;
  }
  .ant-btn {
    padding: 0;
    border: 0;
    padding: 10px 15px;
    @media screen and (max-width: 991px) {
      padding: 5px 3px;
    }
    .icon {
      fill: @white;
      width: 20px;
      @media screen and (max-width: 991px) {
        width: 14px;
      }
    }
  }
}

.ant-modal {
  .ant-modal-close {
    top: 22px;
    right: 22px;
    .ant-modal-close-x {
      .icon {
        fill: @white;
        width: 18px;
      }
    }
  }
  .ant-modal-content {
    background-color: #040c1f;
    border-radius: 20px;
    padding: 1.5rem 2rem;
  }
  .ant-modal-header {
    background: none;
    .ant-modal-title {
      font-size: 2.3rem;
      font-weight: 800;
      color: @white;
    }
  }
}

.container {
  @media screen and (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.copy_code_snippet_container {
  background-color: #051933;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0.5rem;
  border-radius: 10px 10px 0 0;

  .code_title {
    font-size: 18px;
    font-weight: 600;
  }
  .copy_code {
    span {
      display: flex;
      // align-items: center;
      column-gap: 4px;
      cursor: pointer;
      svg {
        font-size: 17px;
      }
    }
  }
}


::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(@secondary-color, 0.4); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(@primary-color, 0.8); 
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}