@import "../../../assets/less/variables.less";

.dexes-list{
    list-style-type: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.4rem;
    margin-top: 1.5rem;
    @media screen and (max-height:576px) {
        grid-template-columns: repeat(2, 1fr);
    }
    li {
        background: #0A1633;
        border-radius: 10px;
        padding: 5px 15px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 30px;
        }
    }
}