@import "../../assets/less/variables.less";

.sidebar {
    background: @sidebar-bg;
    width: 100%;
    max-width: 300px;
    height: calc(100vh - 60px);
}

.footer-main {
    position: relative;
    .footer-bg {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 500px;
        z-index: 0;
    }
    
    .footer-col {
        padding-top: 2rem;
    }

    .ant-divider {
        border-top: 1px solid rgba(@white, 0.7);
    }

    .footerleft-col {
        .icon {
            width: 120px;
        }

        img {
            width: 140px;
            margin-bottom: 18px;
        }

        p {
            font-size: @font-size-base;
            font-weight: 300;
            color: @white;
        }
    }

    h3 {
        font-weight: 700;
        font-size: @font-size-lg;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            display: block;

            .ant-btn-link, a {
                font-size: @font-size-base;
                font-weight: 300;
                color: @white;
                margin-bottom: 5px;

                &:hover {
                    color: rgba(@white, 0.7);
                }

            }
        }
    }

    .footerbottom-left {
        padding-top: 20px;
        padding-bottom: 20px;

        @media screen and (max-width:576px) {
            text-align: center;
            margin-top: 2rem;
        }

        p {
            color: @white;
            margin-bottom: 0;
            font-size: @font-size-sm;
            font-weight: 300;
        }
    }

    .footer-col-last {
        p {
            color: @white;
            font-weight: 300;
            font-size: @font-size-sm + 1;
            line-height: 1.5;
        }
        a {
            color: @white;
        }
    }

    .footerbottom-right {
        text-align: right;
        padding-top: 20px;
        padding-bottom: 20px;

        @media screen and (max-width:576px) {
            text-align: center;
        }

        .footer-links {
            a {
                padding: 8px;

                @media screen and (max-width:576px) {
                    padding: 8px 6px;
                }

                .icon {
                    fill: @white;
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}