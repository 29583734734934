@import "../../assets/less/variables.less";

.homepage-wrapper {
    padding-top: 72px;

    .bannertop {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.6;
        max-width: 320px;
    }

    .banner-section {
        background-size: cover !important;
        position: relative;

        .rightred-bg1 {
            position: absolute;
            right: 0;
            max-width: 30vw;
            bottom: -40%;
            opacity: 0.6;
        }

        .banner-row {
            // min-height: 780px;
            min-height: 620px;
            align-items: center;
            text-align: center;
            position: relative;
            z-index: 2;

            @media screen and (max-width:991px) {
                min-height: 75vh;
                padding-left: 5px;
                padding-right: 5px;
            }

            h1 {
                font-size: 4rem;
                color: @white;
                font-weight: 800;
                margin-bottom: 2.5rem;
                line-height: 1.3;
                letter-spacing: 2px;

                @media screen and (max-width:991px) {
                    font-size: 2.5rem;
                    line-height: 1.3;
                }

                @media screen and (max-width:576px) {
                    font-size: 1.875rem;
                }
            }

            p {
                font-size: 1.3rem;
                margin-bottom: 3rem;
                line-height: 1.6;
                color: @white;
                font-weight: 400;
                max-width: 900px;
                margin: auto auto 2.5rem auto;

                @media screen and (max-width:991px) {
                    font-size: 1rem;
                }

                @media screen and (max-width:576px) {
                    font-size: 0.9rem;
                }
            }

        }
    }

    .statics-section {
        padding: 4rem 0;

        @media screen and (max-width:1024px) {
            padding: 2rem 0;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 30px;

            @media screen and (max-width:1024px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 2rem;
            }

            @media screen and (max-width:576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            li {
                .card {
                    padding: 1px;
                    height: 100%;
                    border-radius: 6px;

                    .card-inner {
                        background: rgba(@secondary-color, 0.3);
                        border-radius: 6px;
                        height: 100%;
                        padding: 18px 24px;
                        background-origin: border-box;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            inset: 0;
                            border-radius: 12px;
                            padding: 1px;
                            background: linear-gradient(-90deg, #053277, rgba(rgba(@white, 0.77)));
                            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                            -webkit-mask-composite: xor;
                            mask-composite: exclude;
                        }

                        .icon {
                            fill: @white;
                            height: 24px;
                            width: 24px;
                            margin-bottom: 10px;
                        }

                        h2 {
                            margin-bottom: 8px;
                            font-weight: 800;
                        }

                        p {
                            margin-bottom: 0;
                            color: @text-color-primary;
                            font-size: @font-size-base;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }

    .introducing-section {
        padding: 10rem 0;

        @media screen and (max-width:1024px) {
            padding: 5rem 0;
        }

        .discover-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 2rem;
            margin-top: 3.5rem;

            @media screen and (max-width:991px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width:640px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .discover-list-card {
                background: linear-gradient(133.91deg, rgba(15, 72, 159, 0.3) -2.91%, rgba(4, 12, 31, 0.38) 99.03%);
                border-radius: 25px;
                padding: 0;
                text-align: left;

                transition: .5s ease;

                &:hover {
                    transform: scale(1.05);

                    .discover-card-img {
                        overflow: hidden;

                        img {
                            transform: scale(1.1);
                        }
                    }
                }

                .discover-card-img {
                    overflow: hidden;
                    border-radius: 25px;

                    img {
                        // border-radius: 25px;
                        width: 100%;
                        transition: .5s ease;

                        // &:hover {
                        //     transform: scale(1.1);
                        // }
                    }
                }

                .discover-card-bottom {
                    padding: 1.4rem 1.5rem;
                    position: relative;

                    h3 {
                        font-size: 1.6rem;
                        font-weight: 700;
                    }

                    .socil-links {
                        position: absolute;
                        top: 20px;
                        right: 15px;

                        a {
                            display: inline-block;
                            padding: 5px;

                            .icon {
                                fill: rgba(@white, 0.5);
                                width: 18px;
                            }
                        }
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }

    }

    .inside-section {
        padding: 5rem 0;
        position: relative;

        .rightred-bg1 {
            position: absolute;
            right: 0;
            max-width: 30vw;
            top: -70%;
            opacity: 0.6;
        }

        .slider-row-wrape {
            width: fit-content;
		    animation: marquee-desktop calc(var(--card-speed, 4s) * var(--card-count, 7)) linear infinite;
        }

        .slider-row {
            margin-top: 3.5rem;
            display: flex;
            grid-gap: 1.5rem;
        }

        .inside-card {
            background: linear-gradient(133.91deg, rgba(15, 72, 159, 0.3) -2.91%, rgba(4, 12, 31, 0.38) 99.03%);
            border-radius: 25px;
            padding: 25px;
            text-align: left;
            min-height: 250px;
            width: 280px;

            &:last-child {
                margin-right: 1.5rem;
            }

            @media screen and (max-width:991px) {
                padding: 30px 22px;
            }

            .icon {
                width: 35px;
                height: 35px;
                fill: #9AB6DB;
                margin-bottom: 1.2rem;
            }

            h3 {
                font-size: 1.3rem;
                font-weight: 800;
                margin-bottom: 0.6rem;
            }

            p {
                margin-bottom: 0;
                font-size: 14px;
                white-space: normal;
            }
        }
    }

    .about-section {
        padding: 6rem 0;
        text-align: center;
        position: relative;

        .leftblue-bg1 {
            position: absolute;
            left: 0;
            max-width: 22vw;
            top: -15%;

            @media screen and (max-width:991px) {
                top: 0;
            }
        }

        .logos-row {
            text-align: center;
            margin-bottom: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-divider {
                height: 65px;
                border-color: @white;
                margin: 0 3rem;
                border-width: 2px;

                @media screen and (max-width:991px) {
                    margin: 0 2rem;
                }
            }

            img {
                max-width: 40%;
            }
        }

        .powering-row {
            margin-top: 5.2rem;
        }

        .left-col {
            padding: 0 5%;

            h1 {
                font-size: 2rem;
            }

            h3 {
                font-weight: 400;
                font-size: 1.8rem;
            }
        }

        .right-col {
            padding: 0 5%;
            position: relative;
            z-index: 1;

            h4 {
                font-weight: 700;
                font-size: 1.4rem;
                display: inline-block;
                position: relative;
                margin-top: 1.2em;
                margin-bottom: 1.5rem;

                &::after {
                    content: ' ';
                    background: @white;
                    height: 1px;
                    max-width: 90px;
                    position: absolute;
                    bottom: -5px;
                    display: block;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }

            h3 {
                font-weight: 700;
                font-size: 1.4rem;
                margin: 18px auto;
            }

            .ant-divider {
                height: 80%;
                border-color: @white;
                margin: 0;
                border-width: 2px;
                position: absolute;
                left: 0;
                bottom: 0;
                top: 0;
                margin: auto;

                @media screen and (max-width:991px) {
                    margin: 0 2rem;
                }

                @media screen and (max-width:767px) {
                    display: none;
                }
            }

            h4 {
                .icon {
                    fill: white;
                }
            }

            .ak-logo {
                max-width: 120px;
            }

            ul {
                list-style-type: none;
                padding-left: 0;
                display: grid;
                grid-gap: 22px;
                grid-template-columns: repeat(7, 1fr);

                li {
                    img {
                        width: 100%;
                    }
                }
            }
        }

    }

    .rwaverview-section {
        padding: 5rem 0;
        position: relative;

        ul {
            list-style-type: none;
            padding-left: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1.5rem;
            margin-top: 4rem;

            @media screen and (max-width:1200px) {
                grid-gap: 1.8rem;
            }

            @media screen and (max-width:991px) {
                grid-gap: 1.8rem;
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width:576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            li {
                background: linear-gradient(133.91deg, rgba(15, 72, 159, 0.3) -2.91%, rgba(4, 12, 31, 0.38) 99.03%);
                border-radius: 25px;
                padding: 38px 30px;
                text-align: left;
                position: relative;
                overflow: hidden;
                transition: .5s ease;

                &:hover {
                    transform: scale(1.05);
                }

                @media screen and (max-width:991px) {
                    padding: 30px 22px;
                }

                .card-img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .upper-section {
                    position: relative;
                    z-index: 1;

                    small {
                        font-size: 14px;
                        font-weight: 400;
                        color: #D7CFCFCC;
                    }

                    h3 {
                        font-size: 1.4rem;
                        font-weight: 800;
                        margin-bottom: 1rem;
                        margin-top: 1rem;
                    }

                    p {
                        margin-bottom: 0;
                        font-weight: 400;
                        line-height: 1.7;
                    }
                }
            }
        }
    }

    .developers-section {
        padding: 5rem 0;
        position: relative;

        @media screen and (max-width:1024px) {
            padding: 4rem 0;
        }

        .rightred-bg1 {
            position: absolute;
            right: 0;
            max-width: 30vw;
            top: -70%;
            opacity: 0.6;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 3rem;
            margin-top: 4rem;

            @media screen and (max-width:1200px) {
                grid-gap: 1.8rem;
            }

            @media screen and (max-width:991px) {
                grid-gap: 1.8rem;
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width:576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            li {
                background: linear-gradient(133.91deg, rgba(15, 72, 159, 0.3) -2.91%, rgba(4, 12, 31, 0.38) 99.03%);
                border-radius: 25px;
                padding: 38px 30px;
                text-align: center;

                @media screen and (max-width:991px) {
                    padding: 30px 22px;
                }

                transition: .5s ease;

                &:hover {
                    transform: scale(1.05);
                }

                .icon-circle {
                    width: 64px;
                    height: 64px;
                    border-radius: 64px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: @white;
                    margin: auto auto 1.8rem auto;

                    .icon {
                        width: 22px;
                    }
                }

                h3 {
                    font-size: 1.4rem;
                    font-weight: 800;
                    margin-bottom: 1rem;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    .discover-section {
        padding: 6rem 0;
        position: relative;

        .leftline-1 {
            position: absolute;
            left: -2%;
            top: -10%;
            opacity: 0.6;
            max-width: 18vw;
        }

        .discover-row {
            margin-top: 5rem;
        }

        .right-col {
            .right-card {
                position: relative;
                height: 100%;
                overflow: hidden;
                border-radius: 25px;
                transition: .5s ease;

                &:hover {
                    transform: scale(1.05);
                }

                .right-card-inner {
                    position: relative;
                    padding: 1.5rem;
                }

                .card-img {
                    width: 101%;
                    height: 101%;
                    object-fit: fill;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .left-icon {
                    width: 70px;
                    height: 55px;
                    min-width: 70px;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(#0F489F, 0.5);
                    margin-right: 1.2rem;
                    padding: 8px;
                    margin-bottom: 1rem;

                    .left-icon-inner {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        background: #0F489F;
                        border-radius: 2px;

                        .icon {
                            fill: @white;
                            width: 25px;
                        }
                    }
                }

                h3 {
                    font-size: 1.5rem;
                    font-weight: 700;
                }
            }
        }
    }

    .pressrelease-section {
        .pressrelease-row {
            background: linear-gradient(90deg, rgba(8, 23, 55, 0) -9.16%, #081737 101.01%);
            margin-top: 3.5rem;
            padding: 3rem 1.5rem;

            div {
                padding: 0 4rem;

                @media screen and (max-width:1300px) {
                    padding: 0 2rem;
                }
            }

            img {
                max-height: 50px;

                @media screen and (max-width:1300px) {
                    max-height: 40px;
                }
            }

            .slick-slide {
                padding: 1.8rem;
            }

            .pressrelease-row-inner {
                display: flex;

                img {
                    cursor: pointer;
                }
            }
        }
    }

    .article-section {
        padding: 6rem 0;
        position: relative;

        .left-bg {
            position: absolute;
            left: 0;
            max-width: 25vw;
            top: -38%;
        }

        .acticle-list {
            position: relative;
            z-index: 1;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 2rem 4rem;
            margin-top: 4rem;

            @media screen and (max-width:1200px) {
                grid-gap: 2rem;
            }

            @media screen and (max-width:991px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width:576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .article-card {
                backdrop-filter: blur(2px);
                border: 1px solid rgba(@white, 0.1);
            }

        }

    }

    .pressrelease-section {
        padding: 8rem 0;
        display: block;
        position: relative;

        @media screen and (max-width:1024px) {
            padding: 3rem 0;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            margin-top: 6rem;

            @media screen and (max-width:767px) {
                grid-template-columns: repeat(3, 1fr);
            }

            li {
                text-align: center;
                width: 25%;
                display: inline-block;
                padding: 34px;

                @media screen and (max-width:767px) {
                    width: 33.33%;
                    padding: 25px 15px;
                }

                &.blank-li {
                    @media screen and (max-width:767px) {
                        display: none;
                    }
                }

                @media screen and (max-width:480px) {
                    width: 50%;
                }

                img {
                    max-width: 100%;
                    max-height: 40px;
                    transition: all ease-in 0.1s;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.05);
                    }
                }

            }
        }
    }

    .social-section {
        padding: 8rem 0;
        position: relative;
        z-index: 1;

        @media screen and (max-width:767px) {
            padding: 5rem 0;
        }

        .leftblue-bg1 {
            position: absolute;
            left: 0;
            max-width: 15vw;
            bottom: -40%;
            opacity: 0.2;
        }

        .social-link {
            display: flex;
            align-items: center;
            margin-top: 3rem;
            margin-bottom: 2rem;

            @media screen and (max-width:767px) {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }

            .left-icon {
                background: rgba(255, 255, 255, 0.02);
                box-shadow: 25px 25px 60px rgba(0, 0, 0, 0.65);
                backdrop-filter: blur(10px);
                border: 1px solid rgba(@white, 0.77);
                width: 89px;
                height: 89px;
                min-width: 89px;
                border-radius: 107px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-right: 22px;

                @media screen and (max-width:767px) {
                    width: 65px;
                    height: 65px;
                    min-width: 65px;
                }

                .icon {
                    fill: @white;
                    width: 50%;
                }
            }

            .right-details {
                .ant-btn-link {
                    display: flex;
                    align-items: center;
                    width: auto;
                    margin-bottom: 0.5rem;

                    &:hover {
                        opacity: 0.8;
                    }

                    h4 {
                        margin-bottom: 0;
                        color: @white;
                        font-size: 1.5rem;
                        font-weight: 700;

                        @media screen and (max-width:767px) {
                            font-size: 1.2rem;
                        }
                    }

                    .ant-btn-icon {
                        margin-inline-end: 0px;
                    }

                    .icon {
                        fill: @white;
                        margin-left: 10px;
                        right: -25px;
                    }
                }

                p {
                    font-weight: 300;
                    font-size: @font-size-sm + 1;
                    max-width: 270px;
                    margin-bottom: 0;
                }
            }
        }
    }
}


.tooltipintro {
    .ant-tooltip-content {
        .ant-tooltip-inner {
            background: rgba(13, 17, 26, 0.95);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 15px;
            border: 1px solid #3f5477;

            .tooltip-wrapper {
                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 5px;
                }

                p {
                    font-size: 12px;
                    font-weight: 500;
                }

                .tags {
                    height: 19px;
                    line-height: 19px;
                    padding: 0 15px;
                    font-size: 10px;
                    text-transform: uppercase;
                    color: @white;
                    display: inline-block;

                    &.live {
                        background: linear-gradient(94.77deg, #008000 -106.7%, #008000 162.99%);
                    }

                    &.devnet {
                        background: #1E7BF1;
                    }

                    &.testnet {
                        background: #FA550C;
                    }

                    &.comingsoon {
                        background: #184CAB;
                    }
                }
            }
        }

        .ant-tooltip-arrow {
            bottom: 1.5px;

            .ant-tooltip-arrow-content {
                background: rgba(13, 17, 26, 0.95);
                width: 14px;
                height: 18px;
                border: 1px solid #3f5477;

                &::before {
                    display: none;
                }
            }
        }
    }
}

.solar {
    position: relative;
    height: 600px;
    transform: rotateX(75deg);

    .solardiv {
        border: 1px solid red;
        position: absolute;
        top: 50%;
        left: 50%;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 100%;
        // transform-style: preserve-3d;
        // animation-name: orbit;
        // animation-iteration-count: infinite;
        // animation-timing-function: linear;

        -webkit-animation: orbit 18s linear infinite;
        -moz-animation: orbit 18s linear infinite;
        -ms-animation: orbit 18s linear infinite;
        -o-animation: orbit 18s linear infinite;
        animation: orbit 18s linear infinite;

        .solar-circle {
            animation-name: suborbit;
            width: 87px;
            height: 87px;
            border-radius: 87px;
            display: inline-block;
            position: absolute;
            z-index: 3;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            // transform: rotateX(150deg) rotateY(-45deg) rotateZ(15deg);
            .icon {
                width: 50px;
                fill: @white;
            }
        }

        &:nth-child(1) {
            width: 76em;
            height: 76em;
            margin-top: -38em;
            margin-left: -38em;

            .solar-circle {
                width: 47px;
                height: 47px;
                background: radial-gradient(72.45% 72.45% at 27.78% 24.07%, #2954A3 0%, #153165 60.46%, #020E24 79.62%, #010B1D 100%)
                    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                ;
                left: 58%;
                bottom: 45%;
                cursor: pointer;
            }
        }

        &:nth-child(2) {
            width: 60em;
            height: 60em;
            margin-top: -30em;
            margin-left: -30em;

            .solar-circle {
                width: 99px;
                height: 99px;
                background: radial-gradient(72.45% 72.45% at 27.78% 24.07%, #F94E16 0%, #7A1E00 60.46%, #531400 79.62%, #401303 100%)
                    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                ;
                left: 18%;
                bottom: 38%;
                cursor: pointer;
            }
        }

        &:nth-child(3) {
            width: 48em;
            height: 48em;
            margin-top: -24em;
            margin-left: -24em;

            .solar-circle {
                width: 69px;
                height: 69px;
                background: radial-gradient(72.45% 72.45% at 27.78% 24.07%, #8F97F1 0%, #2B3170 60.46%, #151A53 79.62%, #060929 100%)
                    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                ;
                left: 33.5%;
                bottom: 33.8%;
                cursor: pointer;
            }
        }

        &:nth-child(4) {
            width: 40em;
            height: 40em;
            margin-top: -20em;
            margin-left: -20em;

            .solar-circle {
                width: 99.54px;
                height: 99.54px;
                background: radial-gradient(72.45% 72.45% at 27.78% 24.07%, #329D63 0%, #0F4E2B 60.46%, #04391C 79.62%, #011209 100%)
                    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                ;
                left: 55%;
                bottom: 91%;
                cursor: pointer;
            }
        }

        &:nth-child(5) {
            width: 30em;
            height: 30em;
            margin-top: -15em;
            margin-left: -15em;

            .solar-circle {
                width: 81px;
                height: 81px;
                background: radial-gradient(72.45% 72.45% at 27.78% 24.07%, #FF8B89 0%, #721614 60.46%, #571211 79.62%, #1A0100 100%)
                    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                ;
                left: 69.3%;
                bottom: 56%;
                cursor: pointer;
            }
        }

        &:nth-child(6) {
            width: 20em;
            height: 20em;
            margin-top: -10em;
            margin-left: -10em;

            .solar-circle {
                width: 103.54px;
                height: 103.54px;
                background: radial-gradient(72.45% 72.45% at 27.78% 24.07%, #1767FE 0%, #061482 60.46%, #010529 79.62%, #010211 100%)
                    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                ;
                left: 85%;
                bottom: 36%;
                cursor: pointer;
            }
        }
    }
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}


@keyframes MoveUpDown {
    0% {
        bottom: 10px;
    }

    50% {
        bottom: 20px;
    }

    100% {
        bottom: 10px;
    }
}

@keyframes orbit {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(-360deg);
    }
}

@keyframes suborbit {
    0% {
        transform: rotateX(90deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(90deg) rotateZ(-360deg);
    }
}

@keyframes invert {
    0% {
        transform: rotateX(-90deg) rotateY(360deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.home_animation_text {
    display: inline-block !important;
    margin-top: 10px;
}


@-webkit-keyframes marquee-desktop {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}

	100% {
		-webkit-transform: translate3d(calc(-50% - 16px), 0, 0);
		transform: translate3d(calc(-50% - 16px), 0, 0)
	}
}

@keyframes marquee-desktop {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}

	100% {
		-webkit-transform: translate3d(calc(-50% - 16px), 0, 0);
		transform: translate3d(calc(-50% - 16px), 0, 0)
	}
}