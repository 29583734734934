@import "../../../assets/less/variables.less";

.homepage-wrapper {
    &.event-details {
        .ticket-action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 450px;
            h4{
                margin-bottom: 0;
                font-weight: 700;
            }
            p{
                margin-bottom: 0;
                font-size: @font-size-base - 1;
            }
        }
        .speakers-section {
            .speaker-list {
                display: flex;
                flex-wrap: wrap;
                list-style-type: none;
                padding-left: 0;
                margin: 2rem 0 4rem 0;
                li {
                    text-align: center;
                    padding: 2rem 0;
                    width: 20%;
                    @media screen and (max-width:1280px) {
                        width: 25%;
                    }
                    @media screen and (max-width:991px) {
                        width: 33.33%;
                    }
                    @media screen and (max-width:767px) {
                        width: 50%;
                    }
                    @media screen and (max-width:480px) {
                        width: 100%;
                    }
                    .image-circle {
                        width: 196px;
                        height: 196px;
                        border-radius: 196px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(15, 72, 159, 0.3);
                        margin:0 auto 1.5rem auto;
                        .image-circle-inner {
                            width: 168px;
                            height: 168px;
                            border-radius: 168px;
                            overflow: hidden;
                            background: #0F489F4D;
                            padding: 14px;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                    h4{
                        font-size: 1.4rem;
                        font-weight: 600;
                        margin-bottom: 0;
                    }
                    p{
                        font-size: 1rem;
                        font-weight: 300;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .speakers-section {
            position: relative;
            .red-right {
                position: absolute;
                right: 0;
                top: -30%;
                opacity: 0.6;
                max-width: 30vw;
            }
            .ant-btn {
                padding: 0 25px 0 0;
                border: 0;
                text-transform: uppercase;
            }
        }
        .eventlist-section {
            position: relative;
            .blue-left {
                position: absolute;
                left: 0;
                bottom: -45%;
                max-width: 22vw;
                opacity: 0.6;
            }
        }
        .eventdtl-section {
            padding: 3rem 0;
            position: relative;
            .ant-btn {
                padding: 0 25px 0 0;
                border: 0;
                text-transform: uppercase;
            }
            .ant-tabs{
                padding-left: 2.5rem;
                @media screen and (max-width:767px) {
                    padding-left: 0;
                    margin-top: 3rem;
                }
                .ant-tabs-nav {
                    &::before {
                        border-color: rgba(#667284, 0.5);
                    }
                    .ant-tabs-nav-wrap{
                        .ant-tabs-nav-list {
                            width: 100%;
                            .ant-tabs-tab  {
                                width: 100%;
                                h3{
                                    margin-bottom: 0;
                                    font-size: 1.5rem;
                                    @media screen and (max-width:767px) {
                                        font-size: 1rem;
                                    }
                                    @media screen and (max-width:576px) {
                                        font-size: 0.85rem;
                                    }
                                }
                                p {
                                    margin-bottom: 0;
                                    @media screen and (max-width:767px) {
                                        font-size: 0.75rem;
                                    }
                                    @media screen and (max-width:576px) {
                                        font-size: 0.70rem;
                                    }
                                }
                            }
                        }
                        .ant-tabs-ink-bar {
                            height: 1px;
                            background: #0F489F;
                        }
                    }
                }
            }
            .schedule-list {
                list-style-type: none;
                padding-left: 0;
                margin-top: 1.2rem;
                li {
                    display: flex;
                    padding: 6px 0;
                    .time {
                        min-width: 140px;
                        color: @white;
                    }
                }
            }
        }
    }
}