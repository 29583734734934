@import "../../assets/less/variables.less";

.homepage-wrapper {
    &.decfund-wrapper {
        .banner-section {
            .banner-row {
                text-align: left;

                p {
                    margin-left: 0;
                }

                h1 {
                    font-size: 5rem;
                    @media screen and (max-width:1024px) {
                        font-size: 3rem;
                    }
                    @media screen and (max-width:991px) {
                        font-size: 2.5rem;
                        line-height: 1.3;
                    }
    
                    @media screen and (max-width:576px) {
                        font-size: 1.875rem;
                    }
                }
            }

            .explore-banner {
                position: absolute;
                right: 0;
                top: 0;
                max-width: 70vw;
            }
        }

        .explore-section-alt {
            padding: 5rem 0;
        }

        .list {
            list-style-type: none;
            padding-left: 0;
            display: grid;
            grid-gap: 3rem;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            margin-top: 3.8rem;

            @media screen and (max-width:1360px) {
                grid-gap: 2rem;
            }

            @media screen and (max-width:1024px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width:640px) {
                grid-template-columns: repeat(1, 1fr);
            }

            li {
                margin-top: 0;

                .explore-card {
                    height: 100%;

                    .explore-card-inner {
                        .card-upper {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;

                            .left-col {
                                .icon-col {
                                    width: 68px;
                                    height: 68px;
                                    border-radius: 68px;
                                    margin-bottom: 1rem;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }

                                h3 {
                                    font-size: 1.7rem;
                                    font-weight: 700;
                                }
                            }

                            .right-col {
                                text-align: right;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;

                                .name {
                                    background: linear-gradient(94.77deg, rgba(15, 72, 159, 0.3) -106.7%, #4DFFFF 162.99%);
                                    padding: 2px 10px;
                                    font-size: 0.8rem;
                                    letter-spacing: 2px;
                                    margin-bottom: 10px;
                                }

                                .status {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    text-transform: uppercase;
                                    letter-spacing: 2px;
                                    font-size: 0.75rem;

                                    span {
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 8px;
                                        display: inline-block;
                                        margin-right: 6px;
                                    }

                                    &.live {
                                        color: #68E971;

                                        span {
                                            background: #68E971;
                                        }
                                    }

                                    &.commingsoon {
                                        color: #87B0EFB2;

                                        span {
                                            background: #87B0EFB2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .lookingfor-section {
            padding: 8rem 0;
            position: relative;
            .line-left {
                position: absolute;
                left: 0;
                max-width: 18vw;
                top: -2%;
            }
            .blue-left {
                position: absolute;
                left: 0;
                max-width: 28vw;
                bottom: 0;
                opacity: 0.5;
            }
            .lookingfor-row {
                padding: 4.5rem 0;
                align-items: center;

                &:nth-child(odd) {
                    @media screen and (max-width:991px) {
                        flex-direction: column-reverse;
                    }   
                }            

                .image-col {
                    text-align: center;
                    .main-img {
                        max-width: 500px;
                        @media screen and (max-width:991px) {
                            max-width: 100%;
                        }
                    }
                    &.image-col1 {
                        .cube-1 {
                            position: absolute;
                            top: -20%;
                            right: 0;
                            max-width: 230px;
                        }
                        .cube-2 {
                            position: absolute;
                            bottom: -10%;
                            left: 0;
                            max-width: 230px;
                        }
                        .cube-3 {
                            position: absolute;
                            left: 0;
                            bottom: 16%;
                            max-width: 32px;
                        }
                        .cube-4 {
                            position: absolute;
                            right: 0;
                            top: 2.5%;
                            max-width: 32px;
                        }
                    }
                    &.image-col2 {
                        .cube-1 {
                            position: absolute;
                            top: -20%;
                            right: 0;
                            max-width: 230px;
                        }
                        .cube-2 {
                            position: absolute;
                            bottom: -10%;
                            left: 0;
                            max-width: 230px;
                        }
                        .cube-3 {
                            position: absolute;
                            left: 0;
                            bottom: 16%;
                            max-width: 32px;
                        }
                        .cube-4 {
                            position: absolute;
                            right: 0;
                            top: 2.5%;
                            max-width: 32px;
                        }
                    }
                    &.image-col3 {
                        .cube-1 {
                            position: absolute;
                            top: -20%;
                            right: 0;
                            max-width: 230px;
                        }
                        .cube-2 {
                            position: absolute;
                            bottom: -10%;
                            left: 0;
                            max-width: 230px;
                        }
                        .cube-3 {
                            position: absolute;
                            left: 0;
                            bottom: 16%;
                            max-width: 32px;
                        }
                        .cube-4 {
                            position: absolute;
                            right: 0;
                            top: 2.5%;
                            max-width: 32px;
                        }
                    }
                }
                .text-col{
                    padding-right: 4rem;
                }
            }
        }
        .eligible-projects {
            position: relative;
            .blue-left {
                position: absolute;
                left: 0;
                top: 0;
                max-width: 28vw;
                opacity: 0.8;
            }
            .blue-line-left {
                position: absolute;
                left: 0;
                top: -5%;
                max-width: 12vw;
                opacity: 0.8;
            }
        }
        .process-section {
            padding: 3rem 0 7rem 0;
            position: relative;
            .blue-right {
                position: absolute;
                right: 0;
                max-width: 28vw;
                top: -4%;
                opacity: 0.7;
            }
            .process-row {
                position: relative;
                margin-top: 3.5rem;
                .ant-divider {
                    border-color: rgba(15, 72, 159, 0.4);
                    border-width: 2px;
                    position: absolute;
                    top: 15px;
                    @media screen and (max-width: 576px) {
                        transform: rotate(90deg);
                        top: 20%;
                        display: none;
                    }
                }
                ul{
                    display: flex;
                    list-style-type: none;
                    padding-left: 0;
                    justify-content: space-between;
                    position: relative;
                    z-index: 1;
                    margin-bottom: 5rem;
                    @media screen and (max-width: 576px) {
                        display: block;
                        &::before {
                            background: rgba(15, 72, 159, 0.3);
                            position: absolute;
                            content: " ";
                            margin: auto;
                            height: 110%;
                            display: block;
                            width: 2px;
                            left: 0;
                            right: 0;
                            top: -5%;
                            z-index: 0;
                        }
                    }
                    li{
                        text-align: center;
                        width: 33.33%;
                        @media screen and (max-width: 576px) {
                            width: 100%;
                            padding: 15px 0;
                            position: relative;
                            z-index: 1;
                        }
                        .numbers {
                            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82"> <g id="Group_16" data-name="Group 16" transform="translate(-5549 -4176)"> <circle id="Ellipse_10" data-name="Ellipse 10" cx="41" cy="41" r="41" transform="translate(5549 4176)" fill="%23071d46"/> <circle id="Ellipse_11" data-name="Ellipse 11" cx="34.724" cy="34.724" r="34.724" transform="translate(5555.275 4182.275)" fill="%23092961"/> </g></svg>');
                            width: 78px;
                            height: 78px;
                            border-radius: 78px;
                            min-width: 78px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 2.3rem;
                            font-weight: 800;
                            margin: 0 auto 1.5rem auto;
                            background-size: 100%;
                            @media screen and (max-width: 767px) {
                                font-size: 1.8rem;
                            }
                        }
                        h3 {
                            font-size: 1.5rem;
                            font-weight: 700;
                            max-width: 300px;
                            margin: auto;
                            @media screen and (max-width: 767px) {
                                font-size: 1.1rem;
                                line-height: 1.4;
                            }
                            @media screen and (max-width: 576px) {
                                background: @secondary-color;
                                padding: 10px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}