@import "../../assets/less/variables.less";

.homepage-wrapper {
    .top-left-bg {
        position: absolute;
        top: -6%;
        left: 0;
        max-width: 400px;
    }
    &.faq-wrapper {
        overflow-y: hidden;
        .faq-banner {
            .banner-row {
                text-align: left;
                min-height: 650px;
                h1 {
                    font-size: 4rem;
                    @media screen and (max-width:1024px) {
                        font-size: 3rem;
                    }
                    @media screen and (max-width:991px) {
                        font-size: 2.5rem;
                        line-height: 1.3;
                    }
                    @media screen and (max-width:767px) {
                        padding-top: 8rem;
                    }
                    @media screen and (max-width:576px) {
                        font-size: 1.875rem;
                    }
                }
            }
            text-align: left;
        }
        .faq-list {
            position: relative;
            padding: 0 0 4rem 0;
            margin-bottom: 5rem;
            @media screen and (max-width:600px) {
                padding: 0 10px 15rem 10px;
            }
            .line-right {
                position: absolute;
                right: 0;
                max-width: 12vw;
                top: 80%;
            }
            .line-left {
                position: absolute;
                left: 0;
                max-width: 12vw;
                top: 80%;
            }
            .blue-bg-left {
                position: absolute;
                left: 0;
                max-width: 28vw;
                top: 40%;
                opacity: 0.6;
            }
            .faq-globe {
                position: absolute;
                right: 0;
                bottom: -38%;
                max-width: 45vw;
            }
            h3 {
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 1.5rem;
            }
            .ant-collapse {
                border: 0;
                border-radius: 0;
                .ant-collapse-item{
                    border: 0;
                    &:last-child {
                        border-radius: 0;
                    }
                    .ant-collapse-header {
                        border-bottom: 1px solid rgba(@white, 0.4);
                        padding: 12px 0;
                        color: rgba(@white, 0.8);
                        font-size: 20px;
                        font-weight: 400;
                        flex-direction: row-reverse;
                        align-items: center;
                        border-radius: 0;
                        @media screen and (max-width:600px) {
                            font-size: 17px;
                        }
                        .ant-collapse-expand-icon {
                            padding-right: 0;
                            .icon {
                                fill: @white;
                                width: 14px;
                            }
                        }
                    }
                    .ant-collapse-content {
                        background: none;
                        .ant-collapse-content-box{
                            padding: 1.4rem 0;
                        }
                        @media screen and (max-width:600px) {
                            p {
                                font-size: 14px;
                            }
                        }
                        a {
                            color: @white;
                            text-decoration: underline;
                            &:hover {
                                color: rgba(@white, 0.8);
                            }
                        }
                    }
                }
            }
        }
    }
}
