@import "../../assets/less/variables.less";

.homepage-wrapper {
   &.developerportal-wrapper {
        .banner-section{
            .banner-row{
                text-align: left;
                p {
                    margin-left: 0;
                }
                h1{
                    font-size: 5rem;
                    @media screen and (max-width:991px) {
                        font-size: 2.5rem;
                        line-height: 1.3;
                    }
    
                    @media screen and (max-width:576px) {
                        font-size: 1.875rem;
                    }
                }
            }
            .explore-banner {
                position: absolute;
                right: -40%;
                top: 5%;
                max-width: 70vw;
            }
        }

        .explore-section-alt {
            padding: 5rem 0;
        }

        .list {
            list-style-type: none;
            padding-left: 0;
            display: grid;
            grid-gap: 3rem;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            margin-top: 3.8rem;
            @media screen and (max-width:1200px) {
                grid-gap: 2rem;
            }
            @media screen and (max-width:991px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media screen and (max-width:640px) {
                grid-template-columns: repeat(1, 1fr);
            }
            li {
                margin-top: 0;
                .explore-card{
                    height: 100%;
                    .explore-card-inner{
                        .card-upper{
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            .left-col {
                                .icon-col {
                                    width: 68px;
                                    height: 68px;
                                    border-radius: 68px;
                                    margin-bottom: 1rem;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                                h3 {
                                    font-size: 1.7rem;
                                    font-weight: 700;
                                }
                            }
                            .right-col {
                                text-align: right;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                .name {
                                    background: linear-gradient(94.77deg, rgba(15, 72, 159, 0.3) -106.7%, #4DFFFF 162.99%);
                                    padding: 2px 10px;
                                    font-size: 0.8rem;
                                    letter-spacing: 2px;
                                    margin-bottom: 10px;
                                }
                                .status {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    text-transform: uppercase;
                                    letter-spacing: 2px;
                                    font-size: 0.75rem;
                                    span {
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 8px;
                                        display: inline-block;
                                        margin-right: 6px;
                                    }
                                    &.live {
                                        color: #68E971;
                                        span {
                                            background: #68E971;
                                        }
                                    }
                                    &.commingsoon {
                                        color: #87B0EFB2;
                                        span {
                                            background: #87B0EFB2;
                                        }
                                    }   
                                }
                            }
                        }
                        .card-bottom {
                            h3 {
                                font-size: 1.7rem;
                                font-weight: 700;
                            }
                            ul {
                                list-style-type: none;
                                padding-left: 0;
                                margin: 0 0 15px 0;
                                li {
                                    padding: 10px 10px 10px 20px;
                                    color: rgba(@white, 0.8);
                                    font-weight: 400;
                                    position: relative;
                                    &::before {
                                        width: 9px;
                                        height: 9px;
                                        border-radius: 9px;
                                        background: #0749B0;
                                        border: 2px solid #063175;
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                }
                            }
                            .ant-btn {
                                border: 0;
                                text-transform: uppercase;
                                padding: 0;
                                font-size: @font-size-sm - 1;
                                letter-spacing: 2px;
                                font-weight: 400;
                                .icon {
                                    right: -24px;
                                    width: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
   }
}

.article-section {
    &.recent-blog {
        padding: 2rem 0 6rem 0;
    }
}