@import "../../../assets/less/variables.less";

.homepage-wrapper {
    &.blog-details {
        position: relative;
        z-index: 1;
        .banner-section {
            padding-top: 12rem;
            position: relative;
            z-index: 2;
            h1{
                font-size: 2.8rem;
            }
        }
        .banner-bg {
            z-index: -1;
            position: absolute;
            right: 0;
            top: 0;
            max-width: 50vw;
        }
        .blog-dtl-banner {
            width: 100%;
            border-radius: 25px;
            margin: 1.5rem auto;
        }
        .blog-details-section {
            position: relative;
            .left-line {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0.6;
                max-width: 20vw;
            }

            .left-bg {
                position: absolute;
                left: 0;
                top: 42%;
                opacity: 0.6;
                max-width: 30vw;
            }

            .left-line2 {
                position: absolute;
                left: 0;
                top: 80%;
                opacity: 0.6;
                max-width: 18vw;
            }

            .left-bg2 {
                position: absolute;
                left: 0;
                top: 80%;
                opacity: 0.6;
                max-width: 22vw;
            }

            .right-line2 {
                position: absolute;
                right: 0;
                top: 50%;
                opacity: 0.6;
                max-width: 18vw;
            }

            .right-bg2 {
                position: absolute;
                right: 0;
                top: 50%;
                opacity: 0.6;
                max-width: 22vw;
            }
            .red-right {
                position: absolute;
                right: 0;
                top: -30%;
                opacity: 0.6;
                max-width: 30vw;
            }
            img {
                max-width: 100%;
                border-radius: 25px;
                margin: 3rem auto;
            }
            p {
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
            h3 {
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 1.5rem;
            }
        }
    }
}