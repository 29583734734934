@import "../../assets/less/variables.less";

.homepage-wrapper {
    &.explore-wrapper {
        .banner-section {
            position: relative;

            .banner-row {
                text-align: left;
                min-height: 600px;
                padding-top: 74px;

                .banner-row-left {
                    position: relative;
                    z-index: 1;
                }

                p {
                    margin-left: 0;
                }

                h1 {
                    font-size: 5rem;

                    @media screen and (max-width:991px) {
                        font-size: 2.5rem;
                        line-height: 1.3;
                    }
    
                    @media screen and (max-width:576px) {
                        font-size: 1.875rem;
                    }
                }
            }

            .explore-banner {
                position: absolute;
                right: 0;
                top: 0;
                max-height: 1060px;

                @media screen and (max-width:991px) {
                    top: auto;
                    bottom: 0;
                }
            }
        }

        .explore-section-alt {
            padding: 0 0 5rem 0;
        }

        .list {
            list-style-type: none;
            padding-left: 0;
            display: grid;
            grid-gap: 3rem;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            margin-top: 3.8rem;

            @media screen and (max-width:1300px) {
                grid-gap: 2rem;
            }

            @media screen and (max-width:1024px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width:640px) {
                grid-template-columns: repeat(1, 1fr);
            }

            li {
                margin-top: 0;

                .ant-btn-link {
                    height: 100%;
                    width: 100%;
                }

                .explore-card {
                    height: 100%;
                    min-height: 340px;

                    .explore-card-inner {
                        h3 {
                            font-size: 1.7rem;
                            font-weight: 700;
                            display: block;

                            @media screen and (max-width:640px) {
                                font-size: 1.3rem;
                            }
                        }

                        .card-upper {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;

                            .left-col {
                                .icon-col {
                                    width: 68px;
                                    height: 68px;
                                    border-radius: 68px;
                                    margin-bottom: 1rem;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }

                            .right-col {
                                text-align: right;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;

                                .name {
                                    background: linear-gradient(94.77deg, rgba(15, 72, 159, 0.3) -106.7%, #4DFFFF 162.99%);
                                    padding: 2px 10px;
                                    font-size: 0.8rem;
                                    letter-spacing: 2px;
                                    margin-bottom: 10px;
                                    color: @white;

                                    @media screen and (max-width:1300px) {
                                        letter-spacing: 0;
                                        font-size: 0.75rem;
                                        padding: 2px 8px;
                                    }
                                }

                                .status {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    text-transform: uppercase;
                                    letter-spacing: 1.5px;
                                    font-size: 0.80rem;
                                    white-space: nowrap;
                                    font-weight: 600;

                                    span {
                                        width: 8px;
                                        height: 8px;
                                        min-width: 8px;
                                        border-radius: 8px;
                                        display: inline-block;
                                        margin-right: 6px;
                                    }

                                    &.live {
                                        color: #68E971;

                                        span {
                                            background: #68E971;
                                        }
                                    }

                                    &.commingsoon {
                                        color: #87B0EFB2;

                                        span {
                                            background: #87B0EFB2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.filterdrop-popup {
    background: #032353;
    border-radius: 10px;

    .ant-dropdown-menu {
        background: #032353;
        border-radius: 10px;

        .ant-dropdown-menu-item {
            color: white;

            .ant-checkbox-wrapper {
                color: @white;
                font-weight: 400;
            }
        }
    }
}

.select-drop {
    margin-right: 15px;

    .ant-select-selector {
        .ant-select-selection-search {}

        .ant-select-selection-item {
            text-align: start;
            font-weight: 400;
        }
    }

    .ant-select-arrow {
        .icon {
            margin-left: 10px;
            fill: @white ;
        }
    }
}

.ant-select-dropdown {
    background: #103A79;
    border: 1px solid #103A79;
    width: 100%;

    .ant-select-item-option-active {}

    .ant-select-item-option-selected {
        background-color: rgba(@primary-color, 0.88) !important;
    }

    .ant-select-item-option-content {
        color: @white;
        font-weight: 400;
    }
}

.filter-row {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 2;

    .filter-drop {
        background: #103A79;
        color: @white;
        border: 1px solid #103A79;
        height: 39px;
        line-height: 39px;
        padding: 0 15px;
        justify-content: space-between;
        font-weight: 400;
        width: 100%;
        max-width: 230px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 15px;

        .icon {
            margin-left: 10px;
            fill: @white;
        }
    }

    .ant-input-affix-wrapper {
        height: 39px;
        background: #103A79;
        text-align: left;
        color: @white;
        border: 1px solid #103A79;
        max-width: 230px;

        .ant-input {
            background: none;
            color: @white;

            &::placeholder {
                color: @white;
                font-weight: 400;
            }
        }

        .ant-input-suffix {
            .icon {
                fill: @white;
            }
        }
    }
}

.explore-card {
    width: 100%;
    background: linear-gradient(133.91deg, rgba(15, 72, 159, 0.3) -2.91%, rgba(4, 12, 31, 0.38) 99.03%);
    border-radius: 25px;
    padding: 1.8rem;

    .explore-card-inner {
        width: 100%;
    }
}