@import "../../../assets/less/variables.less";

.video-card {
    background: rgba(@white, 0.05);
    border-radius: 20px;
    border: 1px solid rgba(@white, 0.05);
    font-family: @font-family;
    .video-card-img {
        height: 207px;
        border-radius: 20px 20px 0 0;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        .cover-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.5;
        }
        .overlay-upper {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            padding: 1.5rem;
            .logos {
                height: 55%;
                img {
                    max-height: 26px;
                    max-width: 100%;
                    width: auto;
                }
            }
            h4 {
                font-size: 18px;
                font-weight: 700;
                span {
                    font-weight: 400;
                    display: block;
                    margin-bottom: 5px;
                }
            }
        }
    }
    .video-card-bottom {
        padding: 1.5rem;
        h3{
            font-size: 1.2rem;
            font-weight: 700;
            min-height: 50px;
            max-width: 96%;
            line-height: 1.4;
            margin-bottom: 1rem;
        }
        p{
            font-size: @font-size-sm + 1;
        }
        ul {
            display: flex;
            align-items: center;
            padding-left: 0;
            list-style-type: none;
            font-weight: 400;
            font-size: 12px;
            margin-bottom: 5px;
            li {
                padding: 0 15px;
                position: relative;
                &:first-child {
                    padding-left: 0;
                }
                &::after {
                    content: ' ';
                    width: 6px;
                    height: 6px;
                    border-radius: 6px;
                    background: @white;
                    position: absolute;
                    right: -2px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}